<template>
  <div
    class="reports"
  >
    <div class="main home-buttons" :class="{widgetTakeover: takeover == true}" v-if="forceScoring">
      <template v-if="multi_tourns == 'Y' && home === true">
        <b-row class="pastbtnRow" :class="{pastBTnPos: takeover == true}">
          <b-col class="tabBtn" v-for="(past, index) in past_tourns" :key="index">
            <b-button
              class="pastbtn"
              @click="swapTour(past.code)"
              :class="{
                activeBtn: course === past.code
              }"
              >{{ past.name }}</b-button
            >
          </b-col>
          <!-- <b-col class="tabBtn" col="12" lg="6" v-else>
            <b-button
              class="pastbtn"
              @click="swapTour(tm.past_tourns.past_tourns_entry.code)"
              :class="{
                activeBtn: course === tm.past_tourns.past_tourns_entry.code
              }"
              >{{ tm.past_tourns.past_tourns_entry.name }}</b-button
            >
          </b-col> -->
          <b-col class="tabBtn ">
            <b-button
              class="pastbtn"
              @click="swapTour(tm.code)"
              :class="{ activeBtn: course === tm.code }"
              >{{ tm.short_name }}</b-button
            >
          </b-col>
          <b-col class="tabBtn" v-if="tm.future_tourns !== ''">
            <b-link
              :to="
                'tournaments-information/' +
                  tm.future_tourns.future_tourns_entry.code +
                  '/' +
                  this.season
              "
            >
              <b-button
                class="pastbtn"
                @click="swapTour(tm.future_tourns.future_tourns_entry.code)"
                :class="{
                  activeBtn:
                    course === tm.future_tourns.future_tourns_entry.code
                }"
                >{{ tm.future_tourns.future_tourns_entry.name }}</b-button
              >
            </b-link>
          </b-col>
        </b-row>
      </template>
      <ReportTable
        :multi_tourns="multi_tourns"
        :pastT="pastT"
        :tm="tm"
        :head="head"
        :data="data"
        :home="home"
        :title="title"
        :report="report"
        :season="season"
        :code="course"
        :takeover="takeover"
        :config="config"
        :team="teamEvent"
        :live="liveOn"
        :match="match"
        :days_away="days_away"
        :live_scoring="live_scoring"
        :web_sponsor="web_sponsor"
        :dic_dates="dic_dates"
      />
    </div>
    <div class="main home-buttons" :class="{widgetTakeover: takeover == true}" v-else>
      <div v-if="showTourn">
        <template v-if="multi_tourns == 'Y' && home === true">
          <b-row class="pastbtnRow" :class="{pastBTnPos: takeover == true}">
            <b-col class="tabBtn" v-for="(past, index) in past_tourns" :key="index" lg="6">
              <b-button
                class="pastbtn"
                @click="swapTour(past.code)"
                :class="{
                  activeBtn: course === past.code
                }"
                >{{ past.name }}</b-button
              >
            </b-col>
            <!-- <b-col class="tabBtn" col="12" lg="6" v-else>
              <b-button
                class="pastbtn"
                @click="swapTour(tm.past_tourns.past_tourns_entry.code)"
                :class="{
                  activeBtn: course === tm.past_tourns.past_tourns_entry.code
                }"
                >{{ tm.past_tourns.past_tourns_entry.name }}</b-button
              >
            </b-col> -->
            <b-col class="tabBtn " lg="6">
              <b-button
                class="pastbtn"
                @click="swapTour(tm.code)"
                :class="{ activeBtn: course === tm.code }"
                >{{ tm.short_name }}</b-button
              >
            </b-col>
            <b-col class="tabBtn" v-if="tm.future_tourns !== ''">
              <b-link
                :to="
                  'tournaments-information/' +
                    tm.future_tourns.future_tourns_entry.code +
                    '/' +
                    this.season
                "
              >
                <b-button
                  class="pastbtn"
                  @click="swapTour(tm.future_tourns.future_tourns_entry.code)"
                  :class="{
                    activeBtn:
                      course === tm.future_tourns.future_tourns_entry.code
                  }"
                  >{{ tm.future_tourns.future_tourns_entry.name }}</b-button
                >
              </b-link>
            </b-col>
          </b-row>
        </template>
        <ReportTable
          :multi_tourns="multi_tourns"
          :pastT="pastT"
          :tm="tm"
          :head="head"
          :data="data"
          :home="home"
          :title="title"
          :report="report"
          :season="season"
          :code="course"
          :takeover="takeover"
          :config="config"
          :team="teamEvent"
          :live="liveOn"
          :match="match"
          :days_away="days_away"
          :live_scoring="live_scoring"
          :web_sponsor="web_sponsor"
          :dic_dates="dic_dates"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "miniLeader",
  props: [
    "season",
    "course",
    "report",
    "title",
    "takeover",
    "config",
    "CurrentReport",
    "forceScoring"
  ],
  components: {
    ReportTable
  },
  data() {
    return {
      tm_params: [],
      data: [],
      home: true,
      tm: "",
      tm_prev: "",
      head: "",
      pastT: "",
      second_tourn_tixc: {},
      winnerType: {},
      showTourn: true,
      days_away: 0
    };
  },
  methods: {
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              this.tm_params.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.tm_params = response.data;
        });
    },
    swapTour(tournCode) {
      this.course = tournCode;
      this.updateStuff();
      this.getSecondTournTicx(tournCode);
    },
    getSecondTournTicx(code) {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.second_tourn_tixc = response.data;
          this.winnerType = response.data.winner_result;
          if (typeof this.second_tourn_tixc.reports != "undefined") {
            this.second_tourn_tixc.reports.reports_entry.forEach(element => {
              if (element.report_url.indexOf("tmscore") !== -1) {
                this.report = element.report_url;
              }
              if (element.report_url.indexOf("tmresult") !== -1) {
                this.report = element.report_url;
                this.updateStuff();
              }
            });
          }
        });
    }
  },
  computed: {
    pastTournreport: function(r) {
      if (this.tm.past_tourns === "") {
        r = this.CurrentReport;
      } else {
        if (this.title.includes("Final")) {
          r = this.CurrentReport;
        } else {
          r =
            "http://sunshinetour.info/tic/tmlsmon.cgi?tourn=" +
            this.pastT +
            "~season=" +
            this.season +
            "~";
          // r = this.currentReport
        }
      }
      return r;
    },
    liveOn: function(message) {
      if (this.live === "Y") message = " - Livescoring is on";
      else message = " - " + this.title;
      return message;
    },
    reportTitle: function(url) {
      if (this.report.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_TIC_BASE +
          this.season +
          "/" +
          this.season +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.report.indexOf("tmscore") !== -1) {
          console.log("this.report");
          console.log(this.report);
          var subRep2 = this.report.lastIndexOf("params=") + 7;
          var indNext2 = this.report.indexOf("~", subRep2);
          var repParams = this.report.substr(subRep2, indNext2 - subRep2);

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              // "-scores-" +
              // repParams +
              "-scores-latest" +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.report.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.report.indexOf("tmdraw") !== -1) {
          var subRep = this.report.lastIndexOf("round=") + 6;
          var indNext = this.report.indexOf("~", subRep);
          var drawRnd = this.report.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.report.indexOf("tmresult") !== -1) {
          var subRep1 = this.report.lastIndexOf("result=") + 7;
          var indNext1 = this.report.indexOf("~", subRep1);
          var resNum1 = this.report.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            this.winnerType +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    }
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          "tmticx?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        this.tm_params = response.data;
        this.web_sponsor = response.data.web_sponsor_logo;
        if (Array.isArray(response.data.past_tourns.past_tourns_entry)) {
          console.log("isArray");
          this.past_tourns = response.data.past_tourns.past_tourns_entry;
        } else {
          console.log("is not Array");
          this.past_tourns = [response.data.past_tourns.past_tourns_entry];
        }
        this.days_away = response.data.days_away;
        this.dic_dates = response.data.dic_dates;
        this.match = response.data.match_play;
        this.tm = response.data;
        this.winnerType = response.data.winner_result;
        console.log("this.winnerType")
        console.log(this.winnerType)
        this.head = response.data.full_name;
        console.log(this.days_away)
        if (this.days_away > 3 || this.days_away < -2 || this.tm_params.tt_code == '34') {
          if (this.course == '1923' || this.course == '1001') {
            this.showTourn = true
          } else {
            this.showTourn = false
          }
        }
        this.multi_tourns = response.data.multi_tourns;
        if (this.multi_tourns == 'Y') {
         this.pastT = response.data.past_tourns.past_tourns_entry.code; 
        }


        return axios.get(this.reportTitle);
      })
      .then(response => {
        this.data = response.data;
        this.live_scoring = response.data.live_scoring;
      });
  }
};
</script>
<style scoped>
::v-deep tbody {
  background: #ffffff;
}
::v-deep tr.homeTd-size>td.LeftAligned{
  width: 30.2%;
}
::v-deep table tbody:nth-child(odd) > tr.homeTd-size>td.LeftAligned{
  border-right: 1px solid #00000008;
}
::v-deep table tbody:nth-child(even) > tr.homeTd-size >td.LeftAligned{
  border-right: 1px solid #fff;
}
::v-deep table tbody:nth-child(odd) > tr.homeTd-size>td.RightAligned{
  border-left: 1px solid #00000008;
}
::v-deep table tbody:nth-child(even) > tr.homeTd-size >td.RightAligned{
  border-left: 1px solid #fff;
}
::v-deep .down{
  color: #1e90ff;
}
::v-deep .up {
  color: #a52a2a!important;
}
::v-deep .courseLogo {
  /* width: 60px; */
}
::v-deep tr.homeTd-size.homeTd-sizehover {
  height: 40px;
}
::v-deep .row.pastbtnRow.pastBTnPos {
  margin-top: 0px!important;
}
::v-deep td {
  font-size: 1rem;
  padding: 0.5rem;
}
.home-buttons {
  /* width: 300px; */
  width: 360px;
  position: absolute;
  top: 1px;
  right: 21.8%;
}
::v-deep .widgetTakeover {
  width: 360px;
  position: absolute;
  /* top: 8px; */
  top: 18px;
  right: -15px;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn {
  background: #03477b !important;
  color: #fff;
  border-radius: 0;
}
::v-deep .tabBtn {
  padding-left: 0px;
  padding-right: 0px;
}
::v-deep .row.pastbtnRow {
  /* top: 1px;
  right: 21.8%;
  position: absolute;
  width: 300px; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 5px !important;
}
::v-deep button.pastbtn {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #015699;
  color: #fff;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn {
  /* background: #005699; */
  background: #03477b;
  color: #fff;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn:hover {
  background: #01477b;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep .MPRound_Name {
  background-color: #01477b !important;
  color: #ffffff;
  margin-top: 0;
  text-align: center;
  padding: 6px;
  margin-bottom: 0;
}
::v-deep td.wineurope {
  color: #fff !important;
}
::v-deep td.winusa {
  color: #fff !important;
}
::v-deep .home.Matchwidth {
  width: 458px !important;
}
::v-deep .overallSection {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0 !important;
}
::v-deep th.LeftAligned {
  text-align: left !important;
  width: 143px;
}
::v-deep tbody {
  border-bottom: 0px solid #fff;
}
@media only screen and (max-width: 1200px) {
  ::v-deep .mobNocontainer.container {
    max-width: 100% !important;
  }
  .home-buttons {
    width: 100%;
    position: initial;
    top: initial;
    right: initial;
  }
  ::v-deep .home {
    width: 100%;
    position: initial;  
    max-width: 100%; 
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .row.pastbtnRow {
    width: 100%;
    margin-left: 0;
    margin-top: 0px;
  }
  .home-buttons {
    width: initial;
    position: initial;
    top: initial;
    right: initial;
  }
}
</style>
