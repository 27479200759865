<template>
  <div id="players">
    <b-container class="ContainerPlayer">
      <b-row class="searchBackground">
        <b-col class="letCol">
          <div class="letterSearch">
            <a href="#A">A</a>
            <span class="pipe-Player">|</span>
            <a href="#B">B</a>
            <span class="pipe-Player">|</span>
            <a href="#C">C</a>
            <span class="pipe-Player">|</span>
            <a href="#D">D</a>
            <span class="pipe-Player">|</span>
            <a href="#E">E</a>
            <span class="pipe-Player">|</span>
            <a href="#F">F</a>
            <span class="pipe-Player">|</span>
            <a href="#G">G</a>
            <span class="pipe-Player">|</span>
            <a href="#H">H</a>
            <span class="pipe-Player">|</span>
            <a href="#I">I</a>
            <span class="pipe-Player">|</span>
            <a href="#J">J</a>
            <span class="pipe-Player">|</span>
            <a href="#K">K</a>
            <span class="pipe-Player">|</span>
            <a href="#L">L</a>
            <span class="pipe-Player">|</span>
            <a href="#M">M</a>
            <span class="pipe-Player">|</span>
            <a href="#N">N</a>
            <span class="pipe-Player">|</span>
            <a href="#O">O</a>
            <span class="pipe-Player">|</span>
            <a href="#P">P</a>
            <span class="pipe-Player">|</span>
            <a href="#Q">Q</a>
            <span class="pipe-Player">|</span>
            <a href="#R">R</a>
            <span class="pipe-Player">|</span>
            <a href="#S">S</a>
            <span class="pipe-Player">|</span>
            <a href="#T">T</a>
            <span class="pipe-Player">|</span>
            <a href="#U">U</a>
            <span class="pipe-Player">|</span>
            <a href="#V">V</a>
            <span class="pipe-Player">|</span>
            <a href="#W">W</a>
            <span class="pipe-Player">|</span>
            <a href="#X">X</a>
            <span class="pipe-Player">|</span>
            <a href="#Y">Y</a>
            <span class="pipe-Player">|</span>
            <a href="#Z">Z</a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="search">
          <b-form-group
            
            label-for="filter-input"           
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Find Player..."
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mobTitle showMob">
        <b-col class="nameMob">Name</b-col>
        <b-col class="natMob">Nationality</b-col>
      </b-row>
      <b-row v-for="(playerlist, index) in filteredList" :key="index" class="mobData showMob" >
        <b-col>
          <template v-if="playerlist.PROFILE == 'Y'">
            <b-nav-item class='MobpLink' :to="'/player-profiles/' + playerlist.REFNO + '/'"> 
              {{playerlist.KNOWN_AS}} {{playerlist.PLAY_SURNAME}}
            </b-nav-item>
          </template>
          <template v-else>
            {{playerlist.KNOWN_AS}} {{playerlist.PLAY_SURNAME}}
          </template>
        </b-col>
        <b-col class="natMob">
          <img
            class="mobFlag"
            :src="
              (config.VUE_APP_FLAG_URL + playerlist.NAT)
                | lowercase
            "
          />
        </b-col>
      </b-row>
      <b-table
        :items="items"
        :fields="fields"
        stacked="md"
        show-empty
        small
        :filter="filter"
        class="tablePlayerList showDesk"
      >
        <template #cell(name)="row">
          <span :id="row.item.SURNAME.slice(0, 1)" class="playerprofileBlockRow">
            <template v-if="row.item.PROFILE == 'Y'">
              <img v-if="row.item.IMAGE == 'Y'" class="profileImage" :src="config.VUE_APP_PLAYER_IMAGE + 'media/photos/' + row.item.REFNO + '.jpg'" onerror="this.style.display='none';">
              <img v-else class="profileImage" :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/Amateur_2.png'" onerror="this.style.display='none';">
              <b-nav-item class='pLink' :to="'/player-profiles/' + row.item.REFNO + '/'">
                {{ row.item.KNOWN_AS }} {{ row.item.PLAY_SURNAME }}
              </b-nav-item>
            </template>
            <template v-else>
              <img v-if="row.item.IMAGE == 'Y'" class="profileImage" :src="config.VUE_APP_PLAYER_IMAGE + 'media/photos/' + row.item.REFNO + '.jpg'" onerror="this.style.display='none';">
              <img v-else class="profileImage" :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/Amateur_2.png'" onerror="this.style.display='none';">
              {{ row.item.KNOWN_AS }} {{ row.item.PLAY_SURNAME }}
            </template>
          </span>
        </template>
        <template #cell(NAT)="row">
          <img
            :class="flag"
            :src="
              (config.VUE_APP_FLAG_URL + row.item.NAT)
                | lowercase
            "
          />
        </template>
        <template #cell(INSTAGRAM)="row">
          <p v-if="Object.keys(row.item.INSTAGRAM).length === 0" class="SocialIcon">
            -
          </p>
          <p v-else class="SocialIcon">
            <b-link :href="'https://www.instagram.com/' + row.item.INSTAGRAM " target="_blank" class="socialLink">
              {{ row.item.INSTAGRAM }}
            </b-link>
          </p>
        </template>

        <template #cell(TWITTER)="row">
          <p v-if="Object.keys(row.item.TWITTER).length === 0" class="SocialIcon">
            -
          </p>
          <p v-else class="SocialIcon">
            <b-link :href="'https://twitter.com/' + row.item.TWITTER " target="_blank" class="socialLink">
              {{ row.item.TWITTER }}
            </b-link>
          </p>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props:['config'],
  data() {
    return {
      items: [],
      details: "details",
      cell: "cell",
      flag: "flag",
      fields: [
        { key: "name", label: "Name" },
        { key: "NAT", label: "Nationality", class: "text-center" },
        { key: "INSTAGRAM", label: "Instagram", class: "text-center HideMob" },
        { key: "TWITTER", label: "Twitter", class: "text-center HideMob" },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: '',
      filterOn: [],
      search: '',
      id:this.$route.query.id
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  computed : {
    filteredList() {
      return this.items.filter(playerlist => {
        return playerlist.KNOWN_AS.toLowerCase().includes(this.filter.toLowerCase())  
        || playerlist.SURNAME.toLowerCase().includes(this.filter.toLowerCase()) 
      })
    }
  },
  mounted() {
    axios //add API Call
      .get(
        'https://api.euro.ocs-software.com/let/cache/let/profiles/index_ASS?randomadd=' + new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.items = response.data.MEMBERS.PLAYER));
  },
};
</script>

<style scoped>
::v-deep tr:hover {
  background-color: #f6f5f5;
  cursor: pointer;
}
.playerprofileBlockRow {
  display: inline-flex;
  padding-top: 15px;
  padding-bottom: 15px;
}
.profileImage {
  height: 50px;
  padding-right: 15px;
  mix-blend-mode: multiply;
}
.nameMob {
  padding-left: 0px;
} 
.natMob {
  text-align: center;
}
.mobFlag {
  margin-right: 15px;
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.mobData {
  margin-left: -6px;
  margin-right: -6px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.mobData:nth-child(odd) {
  background-color: rgba(0,0,0,.05);
}
.mobTitle {
  color: white;
  background-color: #01477b;
  padding: 0.75rem;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: 30px;
}
.tablePlayerList {
  margin-top: 3em;
}
.SocialIcon {
  position: relative;
  top: 10px;
  color: #212529;
  /* text-transform: uppercase; */
}
.SocialIcon:hover {
  position: relative;
  top: 10px;
  color: #035699;
  /* text-transform: uppercase; */
}
li.nav-item.pLink > a:hover {
  color: #035699!important;
}
li.nav-item.pLink > a {
  color: #212529!important;
}
a.socialLink {
  color: #212529;
  text-decoration: none;
}
a.socialLink:hover {
  color: #035699;
  text-decoration: none;
}
li {
  list-style: none;
}
#players {
  padding-top: 30px;
}
.letterSearch > a {
  color: #015699;
  padding: 14.5px;
  font-weight: 500;
  font-size: 1rem;
}
span.pipe-Player {
  color: #cacaca;
}
.letterSearch {
  display: inline-block !important;
  margin-top: 10px;
}
.letCol {
  text-align: center;
}

.searchBackground {
  height: 50px;
  background-color: #f2f2f2;
  margin-left: 0px;
  margin-right: 0px;
}
.profileLink > .nav-link {
  background-color: white;
  color: #263055 !important;
  text-align: left;
  text-decoration: underline;
  font-size: 22px;
  padding: 0;
}
.pLink> .nav-link{
  font-size: 1rem;
  padding: .75rem;
  background-color: transparent;
  color: #212529;
  padding-left: 0px;
}
.MobpLink > .nav-link{
  font-size: 1rem;
  padding: 0rem;
  background-color: transparent;
  color: #212529!important;
  padding-left: 0px;
}
.fRow {
  border-bottom: 1px solid #ddd;
  margin-right: -10px;
}
.playDet > .col {
  border-left: 1px solid #ddd;
}
.playDet > .col:first-child {
  border: none;
}
.playDet {
  padding-top: 20px;
}
::v-deep div > label.text-sm-right {
  display: none !important;
}
::v-deep th {
  color: white;
  background-color: #01477b;
  padding: .75rem;
  width: 17%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 1rem;
  font-weight: 400;
  opacity: 1;
  z-index: 2;
}
::v-deep td{
  font-size: 1rem;
  padding: .75rem;
  padding-bottom: 0px;
  padding-top: 0px;
  border-top: 0px solid #dee2e6!important;
  vertical-align: middle;
}

::v-deep .nav-link {
  color: white !important;
  background-color: #01477b;
  /* width: 140px; */
  width: 100%;
}

::v-deep .btn {
  color: white;
  background-color: #01477b;
}

.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
  /* margin-top: 15px; */
}

::v-deep tr:nth-child(odd) {
  background-color: rgba(0,0,0,.05);
}

.search {
  padding: 1%;
}

::v-deep #filter-input{
  width: 100%;
  background: #f2f2f2;
  border: none;
  padding: 15px;
  text-transform: uppercase;
  margin-top: 1em;
  height: calc(1.5em + 1.75rem + 2px);
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 0px;
}
@media only screen and (max-width: 990px) {
  ::v-deep .searchBackground {
    display: none;
  }
}
@media (min-width: 768px) {
  .showMob {
    display: none;
  }
}
@media (max-width: 768px) {
  .showDesk {
    display: none!important;
  }
  .ContainerPlayer {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  ::v-deep .table.b-table.b-table-stacked-md > caption, .table.b-table.b-table-stacked-md > tbody, .table.b-table.b-table-stacked-md > tbody > tr, .table.b-table.b-table-stacked-md > tbody > tr > td, .table.b-table.b-table-stacked-md > tbody > tr > th {
    display: revert!important;
    width: 100%!important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > thead, .table.b-table.b-table-stacked-md > tfoot {
    display: revert!important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    display: none;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 0px;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > :nth-child(2) {
    border-top-width: 0px;
  }
  ::v-deep .HideMob {
    display: none!important;
  }
}
@media only screen and (max-width: 480px) {
  ::v-deep .nav-link {
    width: 35%;
  }
  .playerPhoto {
    width: 130px;
  }
  ::v-deep .HideMob {
    display: none!important;
  }
  ::v-deep .nav-link {
    width: 100%;
  }
}
</style>
