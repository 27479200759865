<template>
  <div id="Entries" :class="{ home: home === true }">
    <div v-if="home === true">
      <b-container class="mobNocontainer">
        <b-row
          class="homeLeader"
          :class="{ homewidthTakover: takeover == true }"
        >
          <b-col>
            <template v-if="web_sponsor !== ''">
              <b-img v-if="data.code != '1001'" class='courseLogo' :src='web_sponsor'></b-img>
            </template>
            <template v-else>       
              <b-img v-if="data.code != '1001'" class='courseLogo' :src='data.event_sponsor_logo'></b-img>
              <b-img v-else class="courseLogo" :src="data.event_sponsor_logo"></b-img>
            </template>
            <h3 class="homeHead">{{ data.short_name }} - {{ title }}</h3>
            <div class="homecourseDates">{{ data.course_dates }}</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row v-else class="title">
      <b-col class="titleLeftmobileWidth">
        <h3>
          {{ data.full_name }} - {{ title
          }}
        </h3>
      </b-col>
      <b-col class="informationMobilewidth">
        <div class="sponLogo">
          <i v-b-toggle.collapse-1 class="fa-solid fa-circle-info"></i>
        </div>
      </b-col>
    </b-row>
    <template v-if="show_info_web == 'Y'">
      <b-collapse id="collapse-1" class="mt-2 informationCLass" visible>
        <Information
          :data="data"
          :previousData="previousData"
          :home="home"
          :title="title"
          :config="config"
          :team="team"
          :match="match"
          :code="data.code"
          :report="report"
          :live="live"
          :season="data.season"
          :takeover="takeover"
          :bbTeamEvent="data.bball_team"
          :roundsplayed="data.rounds_played"
          :showGender="showGender"
          :Stableford="Stableford"
        />
      </b-collapse>
    </template>
    <template v-else>
      <b-collapse id="collapse-1" class="mt-2 informationCLass">
        <Information
          :data="data"
          :previousData="previousData"
          :home="home"
          :title="title"
          :config="config"
          :team="team"
          :match="match"
          :code="data.code"
          :report="report"
          :live="live"
          :season="data.season"
          :takeover="takeover"
          :bbTeamEvent="data.bball_team"
          :roundsplayed="data.rounds_played"
          :showGender="showGender"
          :Stableford="Stableford"
        />
      </b-collapse>
    </template>
    <table>
      <thead>
        <tr v-if="home === false">
          <template v-if="tablePick === 'Entries'">
            <th>
              No
            </th>
            <th class="playerName">
              Player Name
            </th>
            <th class="hideOnMobile">
              Nat.
            </th>
            <th class="representsText" v-if="data.represents_ind === 'Y'">
              Represents
            </th>
            <th>
              Entry Status
            </th>
            <th class="hideMob">
              Exempt Rank
            </th>
            <th v-if="data.match_play_team == 'Y'" style="padding-left: 10px;">
              Team
            </th>
            <th></th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ trBk: home === true }"
          :key="index"
          v-for="(tabledata, index) in Eds"
        >
          <td
            class="posnum"
            v-if="tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'"
          >
            {{ tabledata.serial_no }}
          </td>
          <td
            colspan="11"
            class="catD"
            v-if="tabledata.playerno === 'Cat' && home === false"
          >
            {{ tabledata.exempt_desc }}
            <span v-if="tabledata.have_exempt_notes == 'Y'">
              <i
                v-b-toggle="'collapse' + index"
                class="fa-solid fa-circle-info"
              ></i>
            </span>
            <b-collapse :id="'collapse' + index" class="mt-2 informationCLass">
              <div class="exemptNotes" v-html="tabledata.exempt_notes"></div>
            </b-collapse>
          </td>
          <td
            colspan="11"
            class="cut"
            v-if="tabledata.playerno === 'EOF' && home === false"
          >
            {{ tabledata.exempt_desc }}
            <span v-if="tabledata.have_exempt_notes == 'Y'">
              <i
                v-b-toggle="'collapse' + index"
                class="fa-solid fa-circle-info"
              ></i>
            </span>
            <b-collapse :id="'collapse' + index" class="mt-2 informationCLass">
              <div class="exemptNotes" v-html="tabledata.exempt_notes"></div>
            </b-collapse>
          </td>

          <td
            class="nameEntries"
            v-if="tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'"
            :class="{ homePlayername: home === true }"
          >
            <template v-if="tabledata.profile == 'Y'">
              <b-link
                :to="'/player-profiles/' + tabledata.playerno"
                class="playerLink"
              >
                <span class="nameEntries"
                  >{{ tabledata.playing_name }}
                  <span v-if="tabledata.amateur_ind == 'A'">(a)</span></span
                >
              </b-link>
            </template>
            <template v-else>
              <span class="nameEntries"
                >{{ tabledata.playing_name }}
                <span v-if="tabledata.amateur_ind == 'A'">(a)</span></span
              >
            </template>
          </td>
          <td
            class="mobHide hideOnMobile"
            v-if="
              tabledata.pos !== 'CUT' &&
                tabledata.playerno !== 'Cat' &&
                tabledata.playerno !== 'EOF' &&
                home === false
            "
          >
            <span v-if="tabledata.nationality === ''"></span>
            <span v-else>
              <img
                class="flag"
                :src="
                  (config.VUE_APP_FLAG_URL + tabledata.nationality) | lowercase
                "
              />
            </span>
          </td>
          <td
            :class="{ hideRepresentsHome: home === true }"
            class="representsText"
            v-if="
              tabledata.pos !== 'CUT' &&
                tabledata.playerno !== 'Cat' &&
                tabledata.playerno !== 'EOF' &&
                data.represents_ind === 'Y'
            "
          >
            <div class="wrap">
              {{ tabledata.represents }}
            </div>
          </td>
          <td
            v-if="
              tabledata.playerno !== 'Cat' &&
                tabledata.playerno !== 'EOF' &&
                home === false
            "
          >
            {{ tabledata.status_text }}
          </td>
          <td
            class="hideMob"
            v-if="
              tabledata.playerno !== 'Cat' &&
                tabledata.playerno !== 'EOF' &&
                home === false
            "
          >
            {{ tabledata.exempt_rank }}
          </td>
          <td
            v-if="
              tabledata.playerno !== 'Cat' &&
                tabledata.playerno !== 'EOF' &&
                data.match_play_team == 'Y'
            "
          >
            <span
              v-bind:style="{ backgroundColor: tabledata.team_colour}"
              :class="{
                europe: tabledata.team_name == 'Europe',
                usa: tabledata.team_name == 'USA'
              }"
            >
              {{ tabledata.team_name }}
            </span>
          </td>
          <td
            v-if="tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'"
          >
            <template v-if="tabledata.profile == 'Y'">
              <a
                :href="'/player-profiles/' + tabledata.playerno"
                target="_blank"
              >
                <span class="iconRight">
                  <font-awesome-icon
                    class="icon"
                    :icon="['fa', 'chevron-right']"
                  />
                </span>
              </a>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="home === true">
      <b-container>
        <b-row class="homeLeaderBelow mobNocontainer">
          <b-col>
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url: this.report,
                  id: season,
                  code: code,
                  title: title
                }
              }"
            >
              <h3 class="FullLeaderboard" :class="{extraPad : multi_tourns == 'Y'}">Full Leaderboard</h3>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Information from "@/components/information.vue";
export default {
  name: "Entries",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "showGender",
    "multi_tourns",
    "web_sponsor",
    "show_info_web",
    "hide_score_card"
  ],
  components: {
    Information
  },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      toggle: ""
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" || this.tablePick === "Live")
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    exemptNotes: function() {
      this.toggle == true;
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry;
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          if (this.multi_tourns == 'Y') {
            reportData = this.data.entries.entries_entry.filter((f) => f.playerno !== "Cat").slice(0, 5);
          } else {
            reportData = this.data.entries.entries_entry.filter((f) => f.playerno !== "Cat").slice(0, 5); 
          }
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
/* Home Styles */
.exemptNotes {
  max-width: 800px;
  text-align: center;
  margin: auto;
}
::v-deep td.PlayerName:hover {
  color: #035699;
}
::v-deep .iconRight:hover {
  color: #035699;
}
::v-deep .PlayerLink:hover {
  color: #035699;
  text-decoration: none;
}
a.playerLink:hover {
  color: #035699;
  text-decoration: none;
}
::v-deep tr:hover {
  background-color: #f6f5f5 !important;
  cursor: pointer;
}
::v-deep i.fa-solid.fa-circle-info.not-collapsed {
  float: right;
  margin-top: 3px;
}
::v-deep i.fa-solid.fa-circle-info.collapsed {
  float: right;
  margin-top: 3px;
}
::v-deep i.fa-solid.fa-circle-info {
  float: right;
  margin-top: 3px;
}
.europe {
  color: #fff !important;
  padding: 0 5px;
}
.usa {
  color: #fff !important;
  padding: 0 5px;
}
::v-deep .informationCLass {
  margin-top: 0px !important;
}
tr.trBk > td {
  font-size: 10pt;
}
tr.trBk {
  background: #fff !important;
  color: #055498;
  border-top: 1px solid #dee2e6 !important;
  font-size: 10pt;
}
tr.trBk:nth-child(even) {
  background: #fff !important;
  color: #055498;
  border-top: 1px solid #dee2e6 !important;
}
tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #212529;
}
td.homePlayername {
  color: #212529;
  font-size: 10pt;
}
a.homePlayername {
  color: #212529;
}
a.homeResult {
  color: #055498 !important;
}
th.representsText {
  text-align: left !important;
  padding-left: 10px;
}
th.playerName {
  padding-left: 13px;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff !important;
  color: #055498;
  border-top: 1px solid #055498 !important;
}
tr.drawHome {
  border-top: 0px solid #055498 !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #055498 !important;
}
tr.homeTd-size > td {
  font-size: 11pt;
  color: #212529;
}
h3.FullLeaderboard {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 13px;
  padding-bottom: 8px;
}
h3.FullLeaderboard.extraPad {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 9px;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 84px;
  padding-top: 25px;
  max-width: 70px;
  min-height: 70px;
  max-width: 84px;
}
img.courseLogo.ss {
  width: 50px;
}
.homeLeader {
  text-align: center !important;
  background-color: #03477b !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  padding-bottom: 10px !important;
}
.homeLeaderBelow {
  text-align: center !important;
  background-color: #03477b !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  padding-bottom: 0px !important;
}

.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homecourseDates {
  font-size: 11pt;
  padding-bottom: 15px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  /* width: 350px; */
  /* width: 300px; */
  /* position: absolute; */
  /* top: 13px; */
  /* top: -80px;  */
  /* right: 30%; */
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #055498;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px;
}
.title {
  text-align: center;
  background-color: #01477b;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #01477b;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #015699;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  /* top: 0; */
  background-color: #42a7c6;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
.even {
  background-color: #e6f4ff !important;
  padding-bottom: 0;
  padding-top: 0;
}
tr:nth-child(even) {
  background-color: #e6f4ff;
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 10pt !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
  .title > .col > h3 {
    color: #fff;
    padding: 15px 0 15px 10px;
    font-size: 11pt;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 24px;
    text-transform: capitalize;
    background-color: #01477b;
    text-align: left;
    font-weight: 400;
  }
  .titleLeftmobileWidth {
    width: 80%;
    max-width: 80%;
  }
  .informationMobilewidth {
    width: 20%;
    max-width: 20%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
  .homewidthTakover {
    margin-left: -15px;
  }
  .wrap {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 480px) {
  .hideMob {
    display: none;
  }
  td.nameEntries {
    padding: 0px;
  }
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>
