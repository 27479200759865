<template>
  <div>
    <Ads
      class="mobHide"
      :page="'newStory'"
      :adNum="'top'"
      :mob="false"
      :side="false"
    />
    <div
      v-if="
        this.postInfo[0].title.rendered === 'Celebrating The Green'
      "
      class="backImage"
    >
      <b-container style="padding-bottom: 2%;">
        <b-row class="mobileWidth"> 
          <b-col class="col-md-8">
            <b-row class="pad celePad">
              <b-col>
                <b-img
                  class="featureImg"
                  :src="this.postInfo[0].acf.main_image"
                ></b-img>
              </b-col>
            </b-row>
            <b-row class="pad celePad">
              <b-col>
                <span class="mainContent"
                  v-html="this.postInfo[0].acf.main_content"
                ></span>
              </b-col>
            </b-row>
            <b-row class="pad celePad">
              <b-col>
                <b-img
                  class="featureImg"
                  :src="this.postInfo[0].acf.green_content_image_one"
                ></b-img>
              </b-col>
            </b-row>
            <b-row class="pad celePad">
              <b-col class="storyFont">
                <b-img
                  class="featureImg"
                  :src="this.postInfo[0].acf.green_content_image_two"
                ></b-img>
              </b-col>
            </b-row>
            <b-row class="pad celePad">
              <b-col>
                <b-img
                  class="featureImg"
                  :src="this.postInfo[0].acf.green_content_image_three"
                ></b-img>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-md-4">
            <b-row>
              <b-col>
                <Newssidebar />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <div class="pad" style="background-color: #f1f1f1; padding-bottom: 2%;">
        <b-container>
          <b-row class="pad">
            <b-col>
              <span
                v-html="this.postInfo[0].acf.valued_partners"
              ></span>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-for="(cg, index) in greenG.slice().reverse()" :key="index">
              <b-card
                :img-src="cg.acf.green_image"
                img-alt="Image"
                img-top
                tag="article"
              >
                <b-card-text v-html="cg.acf.green_content"> </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-row class="pad celePad">
          <b-col>
            <span
              v-html="this.postInfo[0].acf.further_support_title"
            ></span>
          </b-col>
          <b-col>
            <b-img
              style="width: 100px; float: right; margin-bottom: 30px;"
              :src="this.postInfo[0].acf.further_support_image"
            ></b-img>
          </b-col>
        </b-row>
        <b-row class="pad celePad">
          <b-col>
            <span
              v-html="this.postInfo[0].acf.further_support_text"
            ></span>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container v-else class="newsStory-ind">
      <b-row>
        <b-col class="col-md-8" cols="12">
          <b-row class="pad" v-if="this.postInfo[0].slug !== 'let-break-par-challenge'">
            <b-col>
              <h1
                class="under"
                v-html="this.postInfo[0].title.rendered"
              ></h1>
            </b-col>
          </b-row>
          <b-row class="pad" v-if="this.postInfo[0].slug !== 'let-break-par-challenge'">
            <b-col>
              <p>
                <span class="postedOn">Posted on</span> <span class="postDate">{{ dateTime }}</span>
              </p>
            </b-col>
          </b-row>
          <b-row
            class="pad"
            v-if="this.postInfo[0].better_featured_image !== null"
          >
            <b-col>
              <b-img
                class="featureImg"
                :src="
                  this.postInfo[0].better_featured_image.source_url
                "
              ></b-img>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col class="StoryFont">
              <blockquote style='width:600px;' class='instagram-media' data-instgrm-version='14'>
              <!-- replace this href with the link to the post -->
                <a :href="this.postInfo[0].acf.instagram_url_top"></a>
                <script type="application/javascript" src="//www.instagram.com/embed.js"></script>
              </blockquote>
              <span v-html="this.postInfo[0].content.rendered"></span>
              <blockquote class="twitter-tweet">
                  <a :href="this.postInfo[0].acf.twitter_url"></a>
              </blockquote>
              <blockquote style='width:600px;' class='instagram-media' data-instgrm-version='14'>
              <!-- replace this href with the link to the post -->
                <a :href="this.postInfo[0].acf.instagram_url_bottom"></a>
                <script type="application/javascript" src="//www.instagram.com/embed.js"></script>
              </blockquote>
          </b-col>
          </b-row>
          <div class="borderLine"></div>
          <div class="NetWorkSection">
            <b-row class="pad iconNetwork" v-if="this.postInfo[0].slug !== 'let-break-par-challenge'">
              <b-col class="ColNetwidth">
                <ShareNetwork
                  network="facebook"
                  :url="'https://ladieseuropeantour.com/blog/' + this.postInfo[0].slug"
                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                  :title="this.postInfo[0].title"
                  hashtags="LET"
                >
                  <!-- <font-awesome-icon class="icon" :icon="['fab', 'facebook']" /> -->
                  <img class="icon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Facebook_News_Icon.png">
                </ShareNetwork>
              </b-col>
              <b-col class="ColNetwidth">
                <ShareNetwork
                  network="twitter"
                  :url="'https://ladieseuropeantour.com/blog/' + this.postInfo[0].slug"
                  :title="this.postInfo[0].title.rendered"
                  hashtags="LET">
                  <img class="icon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Twitter_NewLogo_v1.png">
                </ShareNetwork>
              </b-col>
              <b-col class="ColNetwidth">
                <ShareNetwork
                  class="twitter"
                  network="pinterest"
                  url="https://www.pinterest.co.uk/login/"
                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                  hashtags="vuejs,vite"
                >
                  <!-- <font-awesome-icon class="icon" :icon="['fab', 'twitter']" /> -->
                  <img class="icon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Pinterest_News_Icon.png">
                  
                </ShareNetwork>
              </b-col>
              <b-col class="col-md-8"> </b-col>
            </b-row>
          </div>
          <!-- <b-row class="pad" v-if="this.postInfo[0].slug !== 'let-break-par-challenge'">
            <b-col>
              <span> TAGGED {{ this.postInfo[0].tags }} </span>
            </b-col>
          </b-row> -->
          <b-row class="pad"> </b-row>
        </b-col>
        <b-col class="col-md-4" cols="12" >
          <b-row>
            <b-col>
              <Newssidebar />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import Ads from "@/components/ads.vue";
import Newssidebar from "@/components/newsSidebar.vue";

export default {
  name: "postFull",
  components: {
    Newssidebar,
    Ads,
  },
  data() {
    return {
      greenG: [],
      postInfo:[]
    };
  },
  computed: {
    dateTime:function(){
      var dateOG = this.postInfo[0].date.split('T')
      var splitDate = dateOG[0].split('-')
      var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
      return newDate

    }
  },
  methods: {
    twitter: function() {
      if (window.twttr) {
        window.twttr.widgets.load();
      }
    }
  },
  mounted(url) {
    axios
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/celebratingthegreen"
      )
      .then(response => {
          (this.greenG = response.data);
          if(this.$route.params.id === 'celebrating-the-green'){
            url = process.env.VUE_APP_WPAPI_URL+"wp/v2/pages?slug="+this.$route.params.id
          }
          else url = process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?slug="+this.$route.params.id 
        return axios.get(
          url
        );
      })
      .then(response => {
        this.postInfo = response.data;
        this.twitter();
      });
  }
};
</script>

<style scoped>
::v-deep img {
  width: 100%;
  height: auto;
}
::v-deep figure.wp-block-gallery.has-nested-images.columns-default.is-cropped.wp-block-gallery-1.is-layout-flex.wp-block-gallery-is-layout-flex {
  display: flex;
}
.celePad {
  background-color: #fff;
}
.backImage {
  /* background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/04/LET-Skin-Graphic.png'); */
}
::v-deep .wp-block-embed__wrapper.iframeBlock > iframe {
  height: 200px;
}
::v-deep h4.wp-block-heading {
  color: #24292e;
  font-weight: 600;
  margin-top: 24px;
  font-family: oswald,helvetica,arial!important;
}
.ColNetwidth {
  display: contents;
}
.icon {
  width: 40px;
  padding-right: 10px;
}
::v-deep .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}
::v-deep td {
  padding: 0.5em;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
}
::v-deep img {
  width: 100%;
  height: auto;
}
a.twitter.share-network-facebook {
  color: rgb(29, 155, 240);
}
a.facebook.share-network-facebook {
  color: #0166FF;
}
.borderLine {
  border-bottom: 1px solid #f0f0f0;
}
.NetWorkSection {
  /* width: 90px; */
  margin-top: 85px;
  margin-bottom: 5em;
  margin-left: 15px;
}
::v-deep .row.pad.iconNetwork {
  margin-top: -3em;
  margin-bottom: 1em;
}
::v-deep iframe {
  width: 100%;
  height: 400px;
}
.newsStory-ind {
  margin-top: 60px;
}
::v-deep iframe#_ytid_31387 {
  width: 100%;
  height: 385px;
}
::v-deep .fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}
::v-deep .alignnone {
  width: 100%;
  height: auto;
}
span.postedOn {
  font-weight: 400;
}
span.postDate {
  color: #015699;
  font-weight: 500;
}
.StoryFont.col {
  font-size: 16px;
  text-transform: initial;
  font-family: Helvetica,Arial!important;
  line-height: 32px;
}
p {
  font-size: 16px;
  text-transform: initial;
  font-family: Helvetica,Arial!important;
  line-height: 32px;
}
.under {
  border-bottom: 1px solid #eaecef;
}
.featureImg {
  width: 100%;
}
.icon {
  font-size: 20pt;
}
.pad {
  padding-top: 2%;
}
.wholePage {
  padding-bottom: 15%;
}
h1 {
  font-size: 3rem;
}
::v-deep span > .wp-block-image > img {
  width: 100%;
}
::v-deep .alignnone {
  width: 100%;
}
::v-deep .card > img {
  background-color: #f1f1f1;
}
::v-deep .card {
  border-color: #f1f1f1;
}
@media only screen and (max-width: 1024px) {
  ::v-deep .miniWrap {
    width: 100%;
    margin-top: -76px;
  }
}
@media only screen and (max-width: 768px) {
  .mobileWidth {
    display: block;
  }
  ::v-deep .outerImg {
    height: fit-content;
    overflow: hidden;
  }
  ::v-deep .recentPosts-block {
    min-width: 100%;
  }
  ::v-deep .miniWrap {
    width: 100%;
    margin-top: -19px;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .epyt-video-wrapper {
    width: 100%;
    display: inline-flex;
    height: 200px;
    max-height: 200px;
  }
  ::v-deep h1.under {
    border-bottom: 1px solid #eaecef;
    font-size: 2rem;
  }
  ::v-deep div#news > div {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
